export default function SearchFilterBar({ value, onChangeHandler, placeholder }) {
    return (
        <div className="search-bar mx-auto mb-8 w-full max-w-[500px] px-5">
            <input
                value={value}
                onChange={onChangeHandler}
                type="search"
                className="form-control w-full rounded border-gray shadow-inherit dark:text-black"
                placeholder={placeholder || 'Type and Filter'}
                id="search-form"
            />
        </div>
    );
}
